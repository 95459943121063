<template>
  <div>
    <van-cell-group title="填写详情">
      <van-field readonly label="仓库" placeholder="请选择仓库" is-link @click="ware.show = true" v-model="ware.txt" />
      <van-popup v-model="ware.show" round position="bottom">
        <van-picker :columns="ware.lis" show-toolbar @cancel="ware.show = false" @confirm="changeWare"
          value-key="STORAGE_NAME" />
      </van-popup>

      <van-field readonly label="分类" placeholder="请选择分类" is-link @click="cat.show = true" v-model="cat.txt" />
      <van-popup v-model="cat.show" round position="bottom">
        <van-cascader title="请选择分类" :options="cat.ls" @finish="catSel" @close="cat.show = false" v-model="model.CAT_ID"
          :field-names="{ text: 'NAME', value: 'ID', children: 'CHILDREN' }" />
      </van-popup>

      <van-field readonly label="物品" placeholder="请选择物品" is-link @click="onStockClick" v-model="stock.txt" />
      <van-popup v-model="stock.show" round position="bottom">
        <van-search @search="onStockSearch" v-model="stock.key" show-action>
          <template #action>
            <van-button type="info" size="small" style="vertical-align: middle" @click="onStockSearch">搜索</van-button>
          </template>
        </van-search>

        <van-picker :columns="stock.lss" show-toolbar @cancel="stock.show = false" @confirm="stockChange">
          <template slot-scope="scope" slot="option">
            <van-cell :title="scope.NAME" :value="scope.BALANCE" />
          </template>
        </van-picker>
      </van-popup>

      <van-field v-model="model.CNT" type="digit" label="数量" placeholder="请输入数量" />
      <van-field v-if="model.TYPE == 2" v-model="model.PRICE" type="number" label="价格" placeholder="请输入物品单价" />

      <!-- <van-field label="仓库" v-model="model.STORAGE_NAME" readonly></van-field> -->

      <van-field v-if="model.TYPE == 1" readonly label="出库人" placeholder="请选择教师" is-link @click="tea.show = true"
        v-model="model.InOut_Stock_User" />
      <van-field v-if="model.TYPE == 2" readonly label="入库人" placeholder="请选择教师" is-link @click="tea.show = true"
        v-model="model.InOut_Stock_User" />
      <van-popup v-model="tea.show" round position="bottom">
        <van-search @search="onTeacherSearch" v-model="teacher.key" />
        <van-picker :columns="teacher.lss" show-toolbar @cancel="tea.show = false" @confirm="teacherWare"
          value-key="NAME" />
      </van-popup>

      <van-field readonly label="经手人" placeholder="请选择教师" is-link @click="teacher.show = true"
        v-model="model.HANDLE_NAME" />
      <van-popup v-model="teacher.show" round position="bottom">
        <van-search @search="onTeacherSearch" v-model="teacher.key" />
        <van-picker :columns="teacher.lss" show-toolbar @cancel="teacher.show = false" @confirm="teacherSel"
          value-key="NAME" />
      </van-popup>

      <van-field v-if="model.TYPE == 1" readonly label="所属班级" placeholder="请选择班级" is-link @click="cla.show = true"
        v-model="model.CLASS_NAME" />
      <van-popup v-model="cla.show" round position="bottom">
        <van-picker :columns="cla.ls" show-toolbar @cancel="cla.show = false" @confirm="classSel" value-key="NAME" />
      </van-popup>

      <van-field v-if="model.TYPE == 1" readonly label="所属位置" placeholder="请选择位置" is-link @click="loc.show = true"
        v-model="loc.txt" />
      <van-popup v-model="loc.show" round position="bottom">
        <van-cascader title="请选择分类" :options="loc.ls" @finish="locSel" @close="loc.show = false" v-model="model.LOC_ID"
          :field-names="{ text: 'NAME', value: 'ID', children: 'CHILDREN' }" />
      </van-popup>
      <!-- <van-field label="所属位置" placeholder="直接输入位置或右边查询按钮选择" v-model="model.LOCATION" >
        <template #right-icon>
          <van-icon name="search"  @click="loc.show=true" />
        </template>
      </van-field>      -->
      <!-- <van-popup v-model="loc.show" round  position="bottom">
        <van-picker :columns="loc.ls" show-toolbar @cancel="loc.show=false" @confirm="locSel" />
      </van-popup> -->
      <van-field readonly label="发生日期" is-link @click="ed_show = true" v-model="model.EVENT_DT" />
      <van-calendar v-model="ed_show" @select="edSel" :show-confirm="false" />
      <van-field v-model="model.DESCRIPTION" type="textarea" label="备注" :rows="2" />
    </van-cell-group>
    <div style="margin: 15px">
      <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading"
        loading-text="正在提交。。。">确认提交</van-button>
      <br />
      <van-button plain type="default" size="large" icon="cross" block to="/mat">取消返回</van-button>
    </div>
  </div>
</template>

<style scoped></style>

<script>
  import moment from "moment";
  import { Toast } from 'vant';
  export default {
    name: "new",
    data() {
      return {
        model: {
          TYPE: this.$route.query.tp,
          EVENT_DT: moment().format('YYYY-MM-DD')
        },
        cat: {
          show: false,
          ls: [],
          all: [],
          txt: ""
        },
        ware: {
          txt: "",
          show: false,
          lis: [],
        },
        stock: {
          show: false,
          ls: [],
          txt: "",
          lss: [],
          key: ""
        },
        ed_show: false,
        teacher: {
          show: false,
          ls: [],
          lss: [],
          key: ""
        },
        tea: {
          show: false
        },
        cla: {
          show: false,
          ls: [],
        },
        ret: {
          loading: false
        },
        loc: {
          show: false,
          ls: [],
          txt: ''
        }
      }
    },
    created() {
      if (this.$route.query.tp == 1) {
        document.title = '新增出库'
        //出库人 默认账号用户
        this.model.InOut_Stock_User = this.whale.user.get().NAME;
      } else if (this.$route.query.tp == 2) {
        document.title = '采购入库'
        //入库人 默认账号用户
        this.model.InOut_Stock_User = this.whale.user.get().NAME;
      }
      this.getWare();
      this.getCat();
      this.getTeacher();
      this.getClass();
      this.getLoc();
    },
    methods: {
      getCat() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/Mobile/MAT/MCatApi/GetTree",
          completed: function (its) {
            self.cat.all = its.DATA;
          }
        })
      },
      getWare() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/Mobile/MAT/MatStorage/GetList",
          completed: function (its) {
            self.ware.lis = its.DATA;
          },
        });
      },
      changeWare(e) {
        this.model.STORAGE_ID = e.ID;
        this.ware.txt = e.STORAGE_NAME;
        this.ware.show = false;
        this.cat.ls = this.cat.all.filter(x => x.STORAGE_ID == e.ID)
        this.model.CAT_ID = 0
        this.cat.txt = ""
        this.model.ST_ID = 0
        this.stock.txt = ""
      },
      catSel({ selectedOptions }) {
        this.stock.txt = ''
        this.model.ST_ID = ''
        this.cat.show = false;
        this.cat.txt = selectedOptions.map((option) => option.NAME).join('/');
        this.getStock();
      },
      onStockClick() {
        this.stock.show = true;
        this.getStock();
      },
      getStock() {
        let self = this;
        this.whale.remote.getCollection({
          url: "/api/Mobile/MAT/MStockApi/GetList",
          data: { CAT_ID: this.model.CAT_ID },
          completed: function (its) {
            self.model.ST_ID = "";
            self.stock.ls = its.ITEMS;
            self.stock.lss = its.ITEMS;
          }
        })
      },
      onStockSearch() {
        this.stock.lss = this.stock.ls.filter(o => {
          return o.NAME.indexOf(this.stock.key) >= 0
        })

        let self = this;
        this.whale.remote.getCollection({
          url: "/api/Mobile/MAT/MStockApi/GetList",
          data: { KEY: this.stock.key },
          completed: function (its) {
            // self.model.ST_ID = "";
            self.stock.ls = its.ITEMS;
            self.stock.lss = its.ITEMS;
          }
        })
      },
      stockChange(o) {
        this.stock.show = false;
        this.stock.txt = o.NAME;
        this.model.ST_ID = o.ID;
        this.model.PRICE = o.PRICE;

        this.model.STORAGE_ID = o.STORAGE_ID
        this.model.STORAGE_NAME = o.STORAGE_NAME
        this.ware.txt = o.STORAGE_NAME
        this.cat.ls = this.cat.all.filter(x => x.STORAGE_ID == o.STORAGE_ID)

        this.model.CAT_ID = o.CAT_ID
        this.model.CAT_NAME = o.CAT_NAME
        this.cat.txt = o.CAT_NAME
      },

      edSel(date) {
        this.ed_show = false;
        this.model.EVENT_DT = moment(date).format('YYYY-MM-DD');
      },
      getTeacher() {
        let self = this;
        this.whale.remote.getCollection({
          url: "/api/Mobile/ORG/TeacherApi/GetList",
          completed: function (its) {
            self.teacher.ls = its.ITEMS;
            self.teacher.lss = its.ITEMS;
          }
        })
      },
      teacherWare(o) {
        this.model.InOut_Stock_User = o.NAME;
        this.tea.show = false;
      },
      teacherSel(o) {
        this.model.HANDLE_NAME = o.NAME;
        this.teacher.show = false;
      },
      onTeacherSearch(v) {
        this.teacher.lss = this.teacher.ls.filter(o => {
          return o.NAME.indexOf(v) >= 0
        })
      },
      getClass() {
        let self = this;
        this.whale.remote.getCollection({
          url: "/api/Mobile/ORG/ClassApi/GetList",
          completed: function (its) {
            self.cla.ls = its.ITEMS;
          }
        })
      },
      classSel(o) {
        this.model.CLASS_ID = o.ID;
        this.model.CLASS_NAME = o.NAME;
        this.cla.show = false;
        this.model.LOC_ID = 0
        this.loc.txt = ''
      },
      getLoc() {
        let self = this;
        this.whale.remote.getCollection({
          url: "/api/Mobile/FIX/LocationApi/GetTree",
          data: {
            // TYPE:'MAT_VALID_LOC'
          },
          completed: function (its) {
            // console.log('its',its)
            // if (n>0){
            //   self.loc.ls =its.ITEMS[0].VALUE.split(',');
            // }
            self.loc.ls = its;
          }
        })
      },
      locSel({ selectedOptions }) {
        this.loc.txt = selectedOptions.map((option) => option.NAME).join('/');
        // this.model.LOCATION=o;
        this.model.CLASS_NAME = ''
        this.model.CLASS_ID = 0
        this.loc.show = false;
      },
      submit() {
        if (!this.model.STORAGE_ID) {
          Toast.fail("请选择仓库")
          return
        }
        if (!this.model.CAT_ID) {
          Toast.fail("请选择分类")
          return
        }
        if (!this.model.CNT) {
          Toast.fail('请填写数量')
          return
        }
        if (this.model.TYPE == 1 && !this.model.CLASS_NAME && !this.model.LOC_ID) {
          Toast.fail("请选择所属班级或所属位置")
          return
        }
        // console.log(this.model)

        let self = this;
        self.ret.loading = true;
        this.whale.remote.getResult({
          url: "/api/Mobile/MAT/MBillApi/Save",
          data: this.model,
          finally() {
            self.ret.loading = false;
          },
          completed() {
            self.$dialog.confirm({
              title: "完成",
              message: "记录已成功提交！",
              theme: "round",
              confirmButtonText: "继续录入",
              cancelButtonText: "返回"
            }).then(() => {
              self.$router.go(0);
            }).catch(() => {
              self.$router.push("/mat")
            })
          }
        })
      }
    }
  }
</script>